import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import L from 'leaflet';

import ChoroplethMap from './ChoroplethMap';
import GraphForArea from './GraphForArea';

const RentalPrice = (props) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [pendingMessage, setpendingMessage] = useState('');
  let { isMapDataPending, isGraphDataPending, mapData, graphData } = props;
  const isPending = isGraphDataPending || isMapDataPending
  // let graphData = props.graphData 
  // let mapData = props.mapData
  useEffect(() => {
    if (isPending) {
      setpendingMessage('計算中です。読み込みまで少々お待ちください…');
    }
    else if (graphData && graphData.y_values.every(value => value === null)) {
      setErrorMessage('賃料指数データの範囲外、もしくはS&Pケース・シラー住宅価格指数計算できないところです。東京23区内で住所を入力、もしくは別のところをクリックしてください');
      setpendingMessage('')
    }
    else {
      setErrorMessage('');
      setpendingMessage('')
    }
  }, [graphData, isPending]);

  var series = [{
    name: '',
    data: graphData === null ? [] : graphData.y_values.map(y => y > 0 ? Math.round(y * 1000) / 10 : null)
  }]

  var options = {
    chart: {
      type: 'line',
      height: '50%',
      width: '100%',
      zoom: { enabled: false },
      toolbar: { show: false }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      type: 'category',
      categories: graphData === null ? [] : graphData.x_values,
      title: {
        text: '西暦'
      },
      min: '2016-01',  // 手动设置最小值
      max: '2024-07',
      tickAmount: 10
    }
  }

  const mapStyle = {
    opacity: 0,
    fillColor: '#66c2a4',
    fillOpacity: 0.8
  }

  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, { radius: 7.5 });
  }

  return (
    <div style={{ height: '100%' }}>
      {
        isPending ? (
          <Grid container flexDirection="column" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography>データ取得中...</Typography>
            </Grid>
          </Grid>
        ) : (
          mapData == null ? null :
            (
              <Grid container justify='center' style={{ height: '100%' }}>
                <Grid item xs={7}>
                  <ChoroplethMap
                    data={mapData}
                    pointToLayer={pointToLayer}
                    isLegend={false}
                    isSelectYear={false}
                    style={mapStyle}
                  />
                </Grid>
                <Grid item xs={5} style={{ padding: 10 }}>
                  {pendingMessage && (
                    <div style={{ color: 'black', fontWeight: 'bold', fontSize: '0.8em', padding: '10px', border: '1px solid black', borderRadius: '5px', margin: '10px 0' }}>
                      {pendingMessage}
                    </div>
                  )}
                  {errorMessage && (
                    <div style={{ color: 'red', fontWeight: 'bold', fontSize: '0.8em', padding: '10px', border: '1px solid red', borderRadius: '5px', margin: '10px 0', backgroundColor: '#ffe6e6' }}>
                      {errorMessage}
                    </div>
                  )}
                  <GraphForArea
                    options={options}
                    series={series}
                    title={'賃料指数推移'}
                  />
                  <div style={{ padding: 10 }}>
                    <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>このデータについて</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 株式会社ワンノブアカインドの運営する
                      <a href="https://www.mansion-review.jp/" target="_blank" rel="noopener noreferrer">マンションレビュー</a>の賃料データより算出</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 地図上の各点はデータに収容されている建物の位置を表す</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• グラフの賃料指数はS&Pケース・シラー住宅価格指数と同じ手法により算出しており、2016年1月を100とした賃料の推移を表す</Typography>
                  </div>
                </Grid>
              </Grid>
            )
        )
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isMapDataPending: state.rentalPrice.isMapDataPending,
    isGraphDataPending: state.rentalPrice.isGraphDataPending,
    mapData: state.rentalPrice.mapData,
    graphData: state.rentalPrice.graphData
  }
}

export default connect(mapStateToProps, null)(RentalPrice);